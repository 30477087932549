.floatBtn {
    position: absolute;
    right: 60px;
    bottom: calc(50vh - 80px)
}
.Btn {
    background-color: black;
    /*width: 72px;*/
    /*height: 72px;*/
    padding: 10px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.5s;
    color: #fff;
    box-shadow: 0px 12px 24px rgba(8, 8, 8, 0.3);
}
.Btn:hover {
    width: auto;
    /*background-color: rgba(0,0,0, 0.1);*/
}
.Btn > .icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
}
.Btn > .text {
    display: none;
}
.Btn:hover > .text {
    display: inline-block;
    margin: 0 10px
}
.Drawer .ant-drawer-content-wrapper, .Drawer.full .ant-drawer-content-wrapper {
    padding: 0;
    width: 100% !important;
}

.query-image {
    width: 150px;
    height: auto;
    object-fit: contain;
    max-height: 300px;
    border-radius: 12px;
}

@media (min-width: 768px) {
    .Drawer .ant-drawer-content-wrapper {
        width: 535px !important;
    }
    .Drawer.full .ant-drawer-content-wrapper {
        width: 85% !important
    }
    .query-image {
        width: 100%;
    }
    .Sidebar {
        overflow-y: auto;
        height: calc(100vh - 54px);
    }
}

