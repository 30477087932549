.Product {
    max-width: 360px;
    flex: 1;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 8px;
    background: white;
    border: 1px solid #f0f0f0;
}
.Product--img {
    object-fit: contain;
    background: white;
    width: 100%;
    height: 160px;
    padding: 10px;
}

.Product--name {
    padding: 0;
    margin: 2px 0;
    color: #323232;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
}

.Product--old-price {
   text-decoration: line-through;
    color: #CCC;
    font-size: 12px;
    margin-right: 10px;
}
.Product--price {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    margin-right: 10px;
    color: #000000a6;
    font-variant: tabular-nums;
    /*margin: 8px 0*/
}
.Product--body {
    padding: 16px 8px;
    background: white;
}

.Products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    grid-gap: 16px;
    padding: 16px;
}
.SimilarProducts--wrapper {
    padding: 20px
}
.SimilarProducts {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: scroll;
    max-width: 100%;
    gap: 20px;
}
.SimilarProducts .Product--img {
    object-fit: contain;
    background: white;
    height: 160px;
    padding: 10px;
}
.Product--link {
    width: 45%;
}
.SimilarProducts .Product {
    /*flex-grow: 0;*/
    display: block;
    overflow: hidden;
    border-radius: 8px;
    background: white;
    border: 1px solid #f0f0f0;
}
@media (min-width: 768px) {
    .Product {
        width: 255px;
        border: 1px solid #f0f0f0;
        flex: unset;
    }
    .Product--price {
        font-size: 14px;
    }
    .Product--name {
        font-size: 16px;
        font-weight: 500;
        margin: 8px 0
    }
    .Product--body {
        padding: 24px;
        background: white;
    }
    .Product--img {
        object-fit: cover;
        height: 300px;
    }
    .ant-card-meta-title {
        margin: 8px 0;
        font-size: 14px
    }
}

.ResultWrapper {
    padding: 0 5px;
    background: #FFF
}
.ResultHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
    padding: 10px 0;
    align-items: center;
    border: 1px solid #00000010;
    border-width: 1px 0
}

@media (min-width: 768px) {
    .Products {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start;
    }
    .SimilarProducts {
        display: flex;
        flex-wrap: nowrap;
        overflow-y: scroll;
        max-width: 100%;
        gap: 20px;
    }

    .SimilarProducts--title {
        /*font-size: 2rem;*/
        font-size: 24px
    }
    .Product--link {
        width: auto;
    }
    .SimilarProducts .Product {
        max-width: 360px;
        border-radius: 8px;
        background: white;
        border: 1px solid #f0f0f0;
    }
    .SimilarProducts .Product--img {
        object-fit: contain;
        background: white;
        height: 250px;
        padding: 10px;
    }
    .ResultWrapper {
        overflow-y: auto;
        height: calc(100vh - 55px);
        position: relative;
        border: 1px solid #00000010;
        border-top: none
    }

}
