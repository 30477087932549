.Message {
    position: relative;
    padding: 20px;
    margin: 10px 0;
    display: none
    /*border-radius: 4px;*/
}
.error {
    background-color: #FAE6E6;
    color: #323232;
}
.close {
    color: #323232;
    right: 5px;
    top: 5px;
    position: absolute;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.show {
    display: block;
}
