/* Works on Firefox */
.lykdat-scrollbar {
  scrollbar-width: 4px;
  scrollbar-color: #999 whitesmoke;
}
/* Works on Chrome, Edge, and Safari */
.lykdat-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.lykdat-scrollbar::-webkit-scrollbar-track {
  background: #00000010;
}
.lykdat-scrollbar::-webkit-scrollbar-thumb {
  background-color: #00000040;
  border-radius: 10px;
}

.overflow-x-scroll::-webkit-scrollbar,
.overflow-x-auto::-webkit-scrollbar {
  height: 4px
}

.overflow-y-scroll::-webkit-scrollbar{
  width: 4px
}

.has-overflow:hover {
  > .overflow-x-scroll::-webkit-scrollbar-thumb,
  > .overflow-x-auto::-webkit-scrollbar-thumb,
  > .overflow-y-scroll::-webkit-scrollbar-thumb,
  > .overflow-y-auto::-webkit-scrollbar-thumb{
    background-color: #00000080;
    transition: 1s all;
  }
  > .overflow-x-scroll::-webkit-scrollbar-track,
  > .overflow-x-auto::-webkit-scrollbar-track,
  > .overflow-y-scroll::-webkit-scrollbar-track,
  > .overflow-y-auto::-webkit-scrollbar-track {
    background: #00000010;
  }
}


