.ant-drawer-content {
    overflow: unset !important;
}

.Sidebar {
    display: none;
    background: #FAFAFA; padding: 30px;
}

.Mobile-preview {
    display: flex;
    padding: 20px
}

.mobile-only {
    position: absolute;
    bottom: 30px;
    display: flex;
    justify-content:  center;
    width: 100%;
    /*right: calc(50vw - 50px);*/
    z-index: 1000;
}

@media (min-width: 768px) {
    .Sidebar {
        display: flex
    }
    .Mobile-preview {
        display: none;
    }
    .Drawer.mobile {
        display: none;
    }
    .mobile-only {
        display: none;
    }

}
